import React from 'react';
import { Link } from 'gatsby';

const NotFound = () =>
  <div style={{ minHeight: '100vh', minWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
    <h1 style={{ fontSize: '50px' }} className='font-bold'>
      404 Not Found
    </h1>
    <Link className='text-[#707085] underline pl-[6px] text-[20px]' to='/'>Go Back Home</Link>
  </div>


export default NotFound;
